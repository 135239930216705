.button {
	cursor: pointer;
	position: relative;
	display: inline-block;
	padding: 10px;
	line-height: 1.1;
	text-decoration: none;
	user-select: none;
	border: 0;
	
	&:hover {
		text-decoration: none !important;
	}

	&.button--totop {
		transition: none;
	}

	&.button--default {
	
		@media screen and (min-width: $break_point) {
			transition: all 0.25s ease;

			&:hover {
			}
			
		}

	}

	&.button--toggle {
		position: fixed;
		right: 0.8rem;
		top: 0.8rem;
		z-index: 200;
		width: 3.1rem;
		height: 3.1rem;
		border: 1px solid $color_white;
		background: #000;
		transform: rotate(0deg);

		span {
			display: block;
			position: absolute;
			height: 46.774%;
			width: 2px;
			background: #FFF;
			opacity: 1;
			bottom: 26.613%;
			transform: rotate(0deg);
			transition: .25s ease-in-out;

			&:nth-child(1) {
				left: 25%;
			}

			&:nth-child(2) {
				left: 49%;
			}

			&:nth-child(3) {
				left: 74%;
			}
		}

		&.button--toggle--open {

			span {

				&:nth-child(1) {
					left: 49%;
					transform: rotate(-45deg);
				}

				&:nth-child(2) {
					opacity: 0;
					bottom: -6rem;
				}

				&:nth-child(3) {
					left: 49%;
					transform: rotate(45deg);
				}
			}
		}
	}

}
@charset "UTF-8";

// base size
$base_width        : 944px;
$break_point       : 780px;

// mixin media query
$phone-upper-boundary: 600px;
$tablet-portrait-upper-boundary: $break_point;
$tablet-landscape-upper-boundary: $base_width;
$desktop-upper-boundary: 1400px;

// font
$sans_serif        : "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Meiryo", "メイリオ", sans-serif;
$serif             : "ヒラギノ明朝 Pro W3" , "Hiragino Mincho Pro" , "HiraMinProN-W3" , "HGS明朝E" , "ＭＳ Ｐ明朝" , "MS PMincho" , serif;
$font_english : 'Open Sans', 'Noto Sans Japanese', "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Meiryo", "メイリオ", sans-serif;

$font_montserrat: 'Montserrat', 'Roboto Condensed', 'Open Sans', sans-serif;

$font_robotocondensed : 'Roboto Condensed', 'Open Sans', sans-serif;

$sp_basic_font_size : 1.2rem;
$pc_basic_font_size : 1.6rem;

// space basis
$sp_pad            : 4%;
$sp_pad_home       : 8.666%;

// color
$base_color        : #000;
$placeholder_color : #c1c1c1;

$color_white : #fff;

$color_darkblue : #151722;

$color_red : #c02320;
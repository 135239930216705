.list-default {

	li { 
	}
}

.list-disc {
	list-style: disc;
	margin-left: 20px;
}

.list-inline {
	
	li {
		display: inline-block;
		margin-right: 10px;
		vertical-align: top;
	}
}

@media screen and (max-width: $break_point) {

	#container {
	}
}

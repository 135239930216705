.icon {
	position: relative;
	display: inline-block;
	background: 0 0 no-repeat;
	background-size: 100% auto;
	vertical-align: top;
	line-height: 1;

	&.icon--arrow {
		top: 8px;
		width: 6px;
		height: 6px;
		border-top: 2px solid #999;
		border-right: 2px solid #999;
		transform: rotate(45deg);
	}

	&.icon--circle-arrow {
		background: #333;
		border-radius: 100px;
		width: 16px;
		height: 16px;

		&:after {
			@include object();
			left: 4px;
			top: 5px;
			width: 4px;
			height: 4px;
			border-top: 2px solid #fff;
			border-right: 2px solid #fff;
			transform: rotate(45deg);
		}

		&.v-top:after {
			left: 5px;
			top: 6px;
			transform: rotate(-45deg);
		}

		&.v-bottom:after {
			left: 5px;
			top: 4px;
			transform: rotate(-225deg);
		}

		&.h-left:after {
			left: 6px;
			transform: rotate(-130deg);
		}
	}

	&.icon--day {
		width: 1rem;
		height: 1rem;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 1rem;
			height: 1rem;
			background: transparent url("../images/content/icon-day.png") center center no-repeat;
			background-size: 100% auto;
		}
	}

	&.icon--night {
		width: 1rem;
		height: 1rem;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 1rem;
			height: 1rem;
			background: transparent url("../images/content/icon-night.png") center center no-repeat;
			background-size: 100% auto;
		}
	}

}
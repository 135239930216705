@charset "UTF-8";

/**
 * :Before :After 要素 指定
 */
@mixin object($pos: absolute) {
	display: block;
	content: "";
	position: $pos;
}

/**
 * ClearFix
 */
@mixin cf() {

	&:after {
		content: "";
		display: block;
		clear: both;
	}
}

/**
 * メディアクエリ 要素個別
 */
@mixin for-size($range) {

	@if $range == phone-only {
		@media (max-width: #{$phone-upper-boundary - 1}) { @content; }
	} @else if $range == tablet-portrait-up {
		@media (min-width: $phone-upper-boundary) { @content; }
	} @else if $range == tablet-landscape-up {
		@media (min-width: $tablet-portrait-upper-boundary) { @content; }
	} @else if $range == desktop-up {
		@media (min-width: $tablet-landscape-upper-boundary) { @content; }
	} @else if $range == big-desktop-up {
		@media (min-width: $desktop-upper-boundary) { @content; }
	}
}

/**
 * メディアクエリ 全体
 */
@mixin for-size-width($limit, $minmax: max, $limit2: none , $minmax2: min ) {

	@if type-of($limit2) == number {
		@media (#{$minmax}-width: $limit) and (#{$minmax2}-width: $limit2) { @content; }
	} @else {
		@if $minmax == max {
			@media (max-width: $limit) { @content; }
		} @else if $minmax == min {
			@media (min-width: $limit) { @content; }
		}
	}
}

/**
 * font-size per window's width
 */
@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
	@each $property in $properties {
		#{$property}: $min-value;
	}

	@media screen and (min-width: $min-vw) {
		@each $property in $properties {
			#{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
		}
	}

	@media screen and (min-width: $max-vw) {
		@each $property in $properties {
			#{$property}: $max-value;
		}
	}
}

@function strip-unit($value) {
	@return $value / ($value * 0 + 1);
}
@charset "UTF-8";

//************************************************

// Reset

//*************************************************

html, body {
	width: 100%;
	font-size: 10px;
}

body {
	font-family: $serif;
	line-height: 1.7;
	font-feature-settings : "palt" 1;
	-webkit-text-size-adjust: 100%;
	text-size-adjust: 100%;
}

body,
h1, h2, h3, h4, h5, h6,
p, ul, ol, dl, dt, dd,
form, fieldset, hr, sup, figure {
	margin: 0;
	padding: 0;
}

ul {
	list-style: none;
}

ol {
	margin-left: 20px;
}

img {
	border: 0;
	vertical-align: top;
	max-width: 100%;
	height: auto;
}

td img {
	vertical-align: baseline;
}

iframe,
fieldset {
	border: 0;
}

input,
select,
textarea {
	font-family: $sans_serif;
}

button,
input[type="submit"],
input[type="button"],
input[type="reset"] {
	-webkit-appearance: none;
	cursor: pointer;
	border: 0;
	border-radius: 0;
}

input[type="tel"],
input[type="text"],
input[type="email"],
input[type="password"],
textarea {
	padding: 5px 8px;
}

input,
select,
button {
	line-height: 1.42857143;
	outline: 0;
}

@media screen and (min-width: $break_point) {

	select {
		background: #FFF;
	}

}

sup {
	font-size: 10px;
	line-height: 1.1;
	vertical-align: top;
}

a {
	color: #000;
	text-decoration: none;
}

ul, ol, p, dt, dd, caption, th, td,
select,input, textarea, button, figcaption {
	font-size: 1.4rem;
}

h1, h2, h3, h4, h5, h6 {
	line-height: 1.4;
	font-size: 1.6rem;
}

table {
	border-collapse: collapse;
	border-spacing: 0;

	td, th {
		line-height: 1.4;
	}
}

hr {
	background: none;
	height: 0;
	border: 0;
	border-top: 1px solid #ccc;
}

main, section, aside, figure, header, footer {
	display: block;
}

* {
	box-sizing: border-box;
}

::-webkit-input-placeholder {
	color: $placeholder_color;
}

:-moz-placeholder {
	color: $placeholder_color;
}

::-moz-placeholder {
	color: $placeholder_color;
}

:-ms-input-placeholder {
	color: $placeholder_color;
}

ul, ol, p, dt, dd,
caption, th, td,
select,input, textarea, button, figcaption {
	@extend .font-basic;
}

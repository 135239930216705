.wrap-table {
	overflow: auto;	
}

.table-default {
	width: 100%;

	tr {
	
		th,
		td {
		}

		th {
		}

		td {}

	}

}

.table-a {

	tr {
		
		th,
		td {}

		th {}

		td {}

	}

}

@media screen and (max-width: $break_point) {

	#container {

		.table-responsive {
			display: block;

			thead, tbody, tr, th, td {
				display: block;
				text-align: left;
			}

		}
	}
}

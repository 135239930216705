@charset "UTF-8";

/* ==========================================
 *
 * Header
 *
 * ========================================== */
.site-header {

	.site-header__inner {
		height: 100vh;
		min-height: 5.68rem;

		@include for-size(tablet-landscape-up) {
			min-height: 73rem;
		}
	}

	.site-header__top {
		position: absolute;
		top: 0;
		left: 4%;
		width: 21.733%;
		z-index: 2;

		@include for-size(tablet-landscape-up) {
			padding-top: 5rem;
			padding-bottom: 9.3rem;
			width: 19rem;
			height: 100vh;
			min-height: 73rem;
			background-color: rgba( $color_white, 0.9 );
		}

		.site-id {
			width: 100%;
			background-color: rgba( $color_white, 0.9 );

			@include for-size(tablet-landscape-up) {
				margin-bottom: 7rem;
				margin-left: auto;
				margin-right: auto;
				width: 68.421%;
				background-color: transparent;
			}
		}

		.nav-sub {

			@include for-size(tablet-landscape-up) {
				margin-left: auto;
				margin-right: auto;
				width: 68.421%;
			}

			ul {

				@include for-size(tablet-landscape-up) {
					display: flex;
					flex-direction: row-reverse;
					justify-content: space-around;
				}

				li {

					@include for-size(tablet-landscape-up) {
						width: 4rem;
					}

					a {
						@extend .link-default;

						@include for-size(tablet-landscape-up) {
							display: block;
							padding-left: 1rem;
							padding-right: 1rem;
						}
					}
				}
			}
		}

		.tel {

			@include for-size(tablet-landscape-up) {
				position: absolute;
				bottom: 0;
				left: 0;
			}
		}
	}

	.copy {
		position: absolute;
		top: 2rem;
		right: 12.133%;
		width: 29.6%;
		z-index: 2;

		@include for-size(tablet-landscape-up) {
			width: 15.3rem;
		}
	}

	.mainvisual {
		position: relative;

		@include for-size(tablet-landscape-up) {
		}

		.slick-slider {

			@include for-size(tablet-landscape-up) {
				height: 100vh;
				min-height: 73rem;
				overflow: hidden;
			}

		}

		.slide-item {
			width: 100%;
			height: 100vh;
			min-height: 5.68rem;

			@include for-size(tablet-landscape-up) {
				min-height: 73rem;
			}

			.slide-item__inner {
				width: 100%;
				height: 100%;
				background-color: transparent;
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
				transition: all 16s;
			}

			&:nth-child(1) {

				.slide-item__inner {
					background-image: url("../images/mainvisual/sp/img01.jpg");

					@include for-size(tablet-landscape-up) {
						background-image: url("../images/mainvisual/pc/img01.jpg");
					}
				}
			}

			&:nth-child(2) {

				.slide-item__inner {
					background-image: url("../images/mainvisual/sp/img02.jpg");

					@include for-size(tablet-landscape-up) {
						background-image: url("../images/mainvisual/pc/img02.jpg");
					}
				}
			}

			&:nth-child(3) {

				.slide-item__inner {
					background-image: url("../images/mainvisual/sp/img03.jpg");

					@include for-size(tablet-landscape-up) {
						background-image: url("../images/mainvisual/pc/img03.jpg");
					}
				}
			}

			&:nth-child(4) {

				.slide-item__inner {
					background-image: url("../images/mainvisual/sp/img04.jpg");

					@include for-size(tablet-landscape-up) {
						background-image: url("../images/mainvisual/pc/img04.jpg");
					}
				}
			}

			&:nth-child(5) {

				.slide-item__inner {
					background-image: url("../images/mainvisual/sp/img05.jpg");

					@include for-size(tablet-landscape-up) {
						background-image: url("../images/mainvisual/pc/img05.jpg");
					}
				}
			}

			&:nth-child(6) {

				.slide-item__inner {
					background-image: url("../images/mainvisual/sp/img06.jpg");

					@include for-size(tablet-landscape-up) {
						background-image: url("../images/mainvisual/pc/img06.jpg");
					}
				}
			}

			&.slick-active {

				.slide-item__inner {
					transform: scale( 1.2 );
				}
			}
		}

		.slick-dots {
			position: absolute;
			bottom: 15vh;
			right: 3.066%;
			z-index: 3;

			@include for-size(tablet-landscape-up) {
				bottom: 2.2rem;
				right: 50%;
				display: flex;
				justify-content: space-between;
				width: 20rem;
				transform: translateX( 50% );
			}

			li {
				position: relative;
				margin: 0.4rem 0;
				width: 4px;
				height: 2.5rem;
				cursor: pointer;

				@include for-size(tablet-landscape-up) {
					width: 2.8rem;
					height: 3px;
				}

				button {
					display: block;
					padding: 0.4rem;
					width: 4px;
					height: 2.5rem;
					border: 0;
					outline: none;
					color: transparent;
					font-size: 0.001px;
					line-height: 0;
					background: transparent;

					@include for-size(tablet-landscape-up) {
						margin-top: -1rem;
						padding: 1rem 0.4rem;
						width: 2.8rem;
						height: 3px;
					}

					&:before {
						content: "";
						position: absolute;
						bottom: 0;
						right: 0;
						width: 2px;
						height: 2.5rem;
						background-color: $color_red;

						@include for-size(tablet-landscape-up) {
							width: 2.8rem;
							height: 1px;
						}
					}
				}

				&.slick-active {

					button {

						&:before {
							width: 4px;

							@include for-size(tablet-landscape-up) {
								width: 2.8rem;
								height: 3px;
							}

						}
					}
				}
			}
		}

		.tel {
			position: absolute;
			bottom: 0;
			left: 0;

			img {
				width: 100%;
				max-width: none;
			}

			&.fixed {
				position: fixed;
			}
		}
	}
}

/* ==========================================
 *
 * Main Navigation
 *
 * ========================================== */
.nav-main {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	padding-top: 36.487vh;
	width: 100%;
	height: 100%;
	z-index: 20;
	background-color: rgba( $color_white, 0.9 );

	@include for-size(tablet-landscape-up) {

	}

	ul {
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-around;
		margin-left: auto;
		margin-right: auto;
		width: 18.9rem;

		@include for-size(tablet-landscape-up) {

		}

		li {
			width: 4.5rem;

			@include for-size(tablet-landscape-up) {

			}

			a {
				@extend .link-default;
				display: block;
				padding-left: 1rem;
				padding-right: 1rem;
			}
		}
	}
}

/* ==========================================
 *
 * Footer
 *
 * ========================================== */
.site-footer {
	padding-top: 3rem;
	padding-bottom: 3rem;

	@include for-size(tablet-landscape-up) {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}

	.copyright {
		font-size: 1rem;
		text-align: center;

		@include for-size(tablet-landscape-up) {
			font-size: 1.5rem;
		}
	}
}
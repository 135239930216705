@charset "UTF-8";

html {
	color: $base_color;
	font-family: $sans_serif;
	@include fluid-type(font-size, 375px, 900px, 10px, 24px );

	@include for-size(tablet-landscape-up) {
		font-size: 10px;
	}
}

body {
}

p {
	font-size: 1.2rem;

	@include for-size(tablet-landscape-up) {
		font-size: 1.8rem;
	}
}

.font-basic {
	font-size: $sp_basic_font_size;

	@include for-size(tablet-landscape-up) {
		font-size: $pc_basic_font_size;
	}
}

.link-default {
	transition: all 0.5s ease;

	&:hover {
		opacity: 0.8;
	}
}

.sp-only {

	@include for-size(tablet-landscape-up) {
		display: none;
	}
}

.pc-only {
	display: none;

	@include for-size(tablet-landscape-up) {
		display: unset;
	}
}

span.pc-only {

	@include for-size(tablet-landscape-up) {
		display: inline-block;
	}
}

div.pc-only {

	@include for-size(tablet-landscape-up) {
		display: block;
	}
}

.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
}

.site-container {
	overflow: hidden;
}
@charset "UTF-8";

/* ==========================================
 *
 * Section
 *
 * ========================================== */
section {

	&[class*="section-"] {

		.section__inner {
			padding-top: 7.45rem;
			padding-bottom: 7.5rem;

			@include for-size(tablet-landscape-up) {
				margin-left: auto;
				margin-right: auto;
				max-width: $base_width;
			}

		}

		.section__header {
			margin-bottom: 2.4rem;

			@include for-size(tablet-landscape-up) {
				margin-bottom: 4rem;
			}

			.heading {
				margin-left: auto;
				margin-right: auto;
				width: 32%;

				@include for-size(tablet-landscape-up) {
					width: 16rem;
				}
			}
		}

		&.section-greeting {

			@include for-size(tablet-landscape-up) {
				background: transparent url("../images/content/bg-greeting-pc.jpg") center center repeat;
				background-size: cover;
			}

			.section__inner {
				padding-bottom: 0;
				min-height: 74.8rem;
				background: transparent url("../images/content/bg-paper.jpg") top left repeat;
				background-size: 100% auto;

				@include for-size(tablet-landscape-up) {
					padding-top: 11.6rem;
					background: transparent none;
				}

			}

			.section__header {
				margin-bottom: 0;

				@include for-size(tablet-landscape-up) {
					margin-bottom: 3.4rem;
					margin-left: 55.72%;
				}

				.heading {
				}
			}

			.section__content {

				@include for-size(tablet-landscape-up) {
					margin-left: 55.72%;
					padding-right: 1.5rem;
				}

				.pic {
					margin-top: -5rem;
				}

				.block-text {
					position: relative;
					padding: 3rem 9% 7rem;

					@include for-size(tablet-landscape-up) {
						padding: 0;
					}

					&:before {
						content: "";
						position: absolute;
						top: 4.2rem ;
						right: 0;
						width: 11rem;
						height: 9.2rem;
						background: transparent url("../images/content/img-flower01.png") center center no-repeat;
						background-size: 100% auto;

						@include for-size(tablet-landscape-up) {
							display: none;
						}
					}

					&:after {
						content: "";
						position: absolute;
						bottom: 2.1rem;
						left: 0;
						width: 10.6rem;
						height: 9.1rem;
						background: transparent url("../images/content/img-flower02.png") center center no-repeat;
						background-size: 100% auto;

						@include for-size(tablet-landscape-up) {
							display: none;
						}
					}

					.text-area {
						margin-bottom: 2.5rem;

						@include for-size(tablet-landscape-up) {
						}

						p {
							font-size: 1.3rem;
							line-height: 1.923;

							@include for-size(tablet-landscape-up) {
								font-size: 1.5rem;
								line-height: 2.125;
							}
						}
					}

					.signature {
						font-size: 1.2rem;
						text-align: right;

						@include for-size(tablet-landscape-up) {
							font-size: 1.5rem;
						}
					}
				}
			}
		}

		&.section-menu {

			.section__inner {

				@include for-size(tablet-landscape-up) {
					padding-left: 2.5rem;
					padding-right: 2.5rem;
				}

			}

			.section__content {

				@include for-size(tablet-landscape-up) {
					display: flex;
					justify-content: space-between;
				}

				.section__header {

					.heading {
						width: 53.4%;

						@include for-size(tablet-landscape-up) {
							width: 16rem;
						}
					}
				}

				.block-description {
					padding-left: 20%;
					padding-right: 20%;

					@include for-size(tablet-landscape-up) {
						padding-left: 0;
						padding-right: 0;
						width: 36.016%;
					}

					.text-area {
						margin-bottom: 2rem;

						@include for-size(tablet-landscape-up) {
							margin-bottom: 4.2rem;
						}

						p {
							font-size: 1.3rem;
							line-height: 1.9;

							@include for-size(tablet-landscape-up) {
								font-size: 1.5rem;
								line-height: 2.3;
							}
						}
					}

					.menu-detail {
						padding-bottom: 2.7rem;

						.heading-hours {
							margin-top: 2.5rem;
							margin-bottom: 0.7rem;
							font-size: 1.25rem;
							font-weight: normal;

							@include for-size(tablet-landscape-up) {
								margin-top: 3.2rem;
								font-size: 1.8rem;
							}

							.icon {
								margin-right: 0.8rem;
								height: 1.2rem;
								vertical-align: middle;

								@include for-size(tablet-landscape-up) {
									width: 1.8rem;
									height: 1.8rem;
								}

								&:before {

									@include for-size(tablet-landscape-up) {
										width: 1.5rem;
										height: 1.5rem;
									}
								}
							}

							.time {
								margin-left: 0.8rem;
								font-size: 1rem;

								@include for-size(tablet-landscape-up) {
									font-size: 1.5rem;
								}
							}
						}

						.text {
							margin-bottom: 0.7rem;
							font-size: 1.25rem;

							@include for-size(tablet-landscape-up) {
								font-size: 1.8rem;
							}

							strong {
								font-weight: bold;
							}
						}

						.note {
							font-size: 1rem;

							@include for-size(tablet-landscape-up) {
								font-size: 1.2rem;
							}
						}
					}
				}

				.block-gallery {
					position: relative;
					padding-left: 12.666%;
					padding-right: 12.666%;

					@include for-size(tablet-landscape-up) {
						padding-left: 0;
						padding-right: 0;
						width: 54.449%;
					}

					&:before {
						content: "";
						position: absolute;
						width: 100%;
						height: 100%;
						z-index: 3;
					}
				}
			}
		}

		&.section-restaurantinfo {
			color: $color_white;
			background: $color_darkblue url("../images/content/bg-restaurantinfo-sp.jpg") top center no-repeat;
			background-size: 100% auto;

			@include for-size(tablet-landscape-up) {
				background-image: url("../images/content/bg-restaurantinfo-pc.jpg");
			}

			.section__inner {

				@include for-size(tablet-landscape-up) {
					padding-top: 7.5rem;
					padding-bottom: 7.2rem;
				}
			}

			.section__header {

				@include for-size(tablet-landscape-up) {
					margin-bottom: 5rem;
				}

				.heading {
				}
			}

			.section__content {

				@include for-size(tablet-landscape-up) {
					display: flex;
					flex-direction: row-reverse;
					justify-content: space-between;
					height: 35.8rem;
				}

				.block-info {
					margin-bottom: 2.5rem;
					padding-left: 8.533%;
					padding-right: 8.533%;

					@include for-size(tablet-landscape-up) {
						margin-bottom: 0;
						padding-left: 0;
						padding-right: 0;
						width: 44.279%;
					}

					dl {
						display: flex;
						justify-content: space-between;
						margin-bottom: 0.7rem;

						dt,dd {
							font-size: 1.3rem;
							line-height: 1.9;

							@include for-size(tablet-landscape-up) {
								font-size: 1.4rem;
								line-height: 2.125;
							}

							p {
								font-size: 1.3rem;

								@include for-size(tablet-landscape-up) {
									font-size: 1.4rem;
								}
							}

							a {
								color: $color_white;
							}
						}

						dt {
							width: 19.614%;

							@include for-size(tablet-landscape-up) {
								width: 16.746%;
							}
						}

						dd {
							width: 74.919%;

							@include for-size(tablet-landscape-up) {
								width: 78.947%;
							}

							address {
								font-style: normal;
							}
						}
					}
				}

				.block-map {
					padding-left: 6.666%;
					padding-right: 6.666%;

					@include for-size(tablet-landscape-up) {
						padding-left: 0;
						padding-right: 0;
						width: 51.906%;
					}

					.map {
						height: 32.1rem;

						@include for-size(tablet-landscape-up) {
							height: 100%;
						}

						iframe {
							width: 100%;
							height: 100%;
						}
					}
				}
			}
		}
	}
}
@charset "UTF-8";

.fixed-width {
	margin-right: auto;
	margin-left: auto;
	width: auto;

	@include for-size(tablet-landscape-up) {
		max-width: $base_width;
	}
}

.col {
	zoom: 1;

	&:after {
		content: '';
		display: block;
		clear: both;
		overflow: hidden;
	}

	.col__item {

		@include for-size(tablet-landscape-up) {
		}
	}

	.col__item--left {
		margin-bottom: 1rem;

		@include for-size(tablet-landscape-up) {
			float: left;
			margin-bottom: 2rem;
			margin-right: 4rem;
		}
	}

	.col__item--right {
		margin-bottom: 1rem;

		@include for-size(tablet-landscape-up) {
			float: right;
			margin-bottom: 2rem;
			margin-left: 4rem;
		}
	}

	&.col--2 {

		.col__item {

			@include for-size(tablet-landscape-up) {
				width: 49%;
			}

			&:nth-child(2n) {

				@include for-size(tablet-landscape-up) {
					float: right;
				}
			}

		}
	}

	&.col--3 {

		.col__item {

			@include for-size(tablet-landscape-up) {
				margin-right: 2%;
				width: 32%;
			}

			&:nth-child(3n) {

				@include for-size(tablet-landscape-up) {
					margin-right: 0;
				}
			}
		}
	}
}

.flex {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;

	&.flex--col-2 {

		.flex__item {
			width: 48%;
		}

	}

	&.flex--col-3 {
		justify-content: flex-start;

		.flex__item {
			width: 30.762%;
			margin-right: 3.846%;

			&:nth-child(3n) {
				margin-right: 0;
			}

		}
	}

	&.flex--pc-col-3 {

		@include for-size(tablet-landscape-up) {
			justify-content: flex-start;
		}

		.flex__item {

			@include for-size(tablet-landscape-up) {
				width: 30.762%;
				margin-right: 3.846%;
			}

			&:nth-child(3n) {

				@include for-size(tablet-landscape-up) {
					margin-right: 0;
				}
			}

		}
	}

	.flex--reset-sp {
		display: block;

		.flex__item {
			width: auto;
		}

	}
}